@import "~@angular/material/theming";
$custom-typography: mat-typography-config(
  $font-family: "Poppins",
);
@include mat-core($custom-typography);

@include angular-material-typography($custom-typography);
html,
body {
  min-height: 100%;
}

// .container-fluid {
//   height: calc(100vh - 250px) !important;
// }

.wrapper {
  position: relative;
  min-height: calc(100vh - 250px);
  height: 100%;
}

.muted {
  color: #818181;
}

.pointer {
  cursor: pointer;
}

.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  display: flex;
  align-items: center;
}

.text_left {
  text-align: left;
}

a {
  text-decoration: none;
  color: black;
  &:hover {
    // text-decoration: none;
    color: black;
    opacity: 0.7;
  }
}

.hover {
  &:hover {
    opacity: 0.7;
  }
}

.hover_2 {
  &:hover {
    color: black;
  }
}

.font-10 {
  font-size: 10px;
}

.font-11 {
  font-size: 11px;
}

.font-12 {
  font-size: 12px;
}

.font-13 {
  font-size: 13px;
}

.font-14 {
  font-size: 14px;
}

.font-15 {
  font-size: 15px;
}

.font-16 {
  font-size: 16px;
}

.font-bold {
  font-weight: bold;
}

.font-semi-bold {
  font-weight: 500;
}

.font-regular {
  font-weight: 400;
}

.text_underline {
  text-decoration: underline;
}

button {
  height: 48px;
}

.container-fluid {
  max-width: 14 00px;
}

.error {
  color: red;
}

.mat-progress-spinner {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mat-expansion-panel {
  background-color: transparent;
}

.carousel-container {
  .carousel-cells {
    .carousel-cell {
      img {
        object-fit: contain !important;
      }
    }
  }
}
